import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf from 'components/related/Form'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    min-height: 50px;
    padding: 10px;
    width: 100%;
  }

  & .form-duuf-textarea {
    height: 150px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    color: ${({ theme }) => theme.color.light};
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    & button {
      width: 100%;
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const FormContent = styled(ParseContent)`
  & img {
    object-fit: contain !important;
  }
`

const StyledForm = styled.div`
  background-color: ${({ theme }) => theme.color.dark};
  border-radius: 15px;
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <section>
    <div className="container py-5 my-lg-5">
      <div className="row mt-lg-5">
        <div className="col-md-4 col-lg-6">
          <FormContent content={fields.description} />
        </div>
        <div className="col-md-8 col-lg-6 mt-5 mt-md-0">
          <StyledForm className="px-4 px-lg-5 pt-5 pb-3">
            <StyledFormDuuf id={1} generate />
          </StyledForm>
        </div>
      </div>
    </div>
  </section>
)

export default FormContact
